.voting {
  margin-top: 20px;
  flex-direction: row;
  display: flex;
  justify-content: right;
  align-items: center;
  height: 100%;
}

.vote-info {
  font-style: italic;
  margin-bottom: 0 !important;
}

.voted {
  justify-content: right;
  align-items: center;
  height: 100%;
}

#up-vote {
  color: #118819;
  margin-right: 5px;
}

#down-vote {
  color: #c41515;
  margin-right: 5px;
}

#up-vote-clicked {
  color: #118819;
  background: lightblue;
  border: 0.5px solid var(--gray-300, #d0d5dd);
  border-radius: 12px;
  margin-right: 5px;
}

#down-vote-clicked {
  color: #c41515;
  background: lightblue;
  border: 0.5px solid var(--gray-300, #d0d5dd);
  border-radius: 12px;
  margin-right: 5px;
}
