.heading-container {
  display: flex;
  align-items: center;
}

.heading-logo {
  margin-right: 10px;
}

.heading {
  margin: 0;
  font-size: 3rem;
}
