.js-plotly-plot {
  width: 700px;
  height: 100%;
}

.plotly-question_recap {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
