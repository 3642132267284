.side-menu-recent-chats-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

#side-menu-recent-chats-truncate {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* Number of lines to show */
  overflow: hidden;
  text-overflow: ellipsis;
  color: #008296;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

#side-menu-recent-chats-timestamp {
  color: var(--gray-500, #667085);
  margin: 0px 0px 0px;
  padding: 0px 0px 0px;
}
