.main {
  flex: 1;
  min-height: 100vh;
  padding-bottom: 15vh;
  position: relative;
}

.main .nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.main .nav .nav-info {
  font-size: 22px;
  color: #585858;
}

.main-container {
  max-width: 900px;
  margin: auto;
}

.main .greet {
  margin: 50px 0px;
  padding: 20px;
}
.main .greet .greeting {
  font-size: 56px;
  color: #c4c7c5;
  font-weight: 500;
}

.main .greet .greeting span {
  background: linear-gradient(16deg, #4bffa6, #4b90ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.main .greet .greeting-info {
  padding-top: 20px;
  font-size: 56px;
  color: #c4c7c5;
  font-weight: 500;
}

::-webkit-scrollbar {
  display: none;
}

.unauthorized {
  text-align: left;
  padding: 50px;
}
