.breakdown-cards div[mdn-card] {
  cursor: pointer;
  position: relative;

  section {
    background: #f0f4f9;
    border-radius: 10px;
  }
}

.injury-cards div[mdn-card] {
  cursor: pointer;
  position: relative;

  section {
    background: #d9dee6;
    border-radius: 10px;
  }
}

.high-level-questions-cards div[mdn-card] {
  cursor: pointer;
  position: relative;

  section {
    background: #c3c9d2;
    border-radius: 10px;
  }
}

.chat-cards [mdn-card-children] {
  height: 200px;
}

div[mdn-card]:hover {
  section {
    background: #a5adb6;
  }
}
